<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Gerencie NF-e emitidas pelo sistema ou emitidas para uma de suas lojas. Aceite ou recuse notas, vincule itens da nota a produtos cadastrados no sistema.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
						<div class="col-6 col-lg-2 mb-3">
							<label><i class="far fa-store color-theme font-11 me-1"></i> Loja</label>
							<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="pesquisa.loja" label="nomeFantasia" :reduce="loja => loja.id" placeholder="Todas as lojas">
								<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
								<template v-slot:no-options>
									<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
								</template>
							</v-select>
							<select name="Mobile" class="form-control" v-model="pesquisa.loja">
								<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
							</select>
                  </div>
						<div class="col-6 col-lg-2 mb-3">
							<label><i class="far fa-exchange-alt color-theme font-11 me-1"></i> Status</label>
							<v-select name="Desktop" :options="['Manifestada', 'Recusada', 'Aceita', 'Recebimento - Entregue', 'Recebimento - Validado']" v-model="pesquisa.status" :searchable="false" placeholder="Todos os status" />
							<select name="Mobile" class="form-control" v-model="pesquisa.status">
								<option v-for="(option, index) in ['Manifestada', 'Recusada', 'Aceita', 'Recebimento - Entregue', 'Recebimento - Validado']" :key="index" :value="option">{{ option }}</option>
							</select>
						</div>
                  <div class="mb-3" :class="dadosUsuario.usuarioPermissoes.includes('Fiscal | Importar notas') ? 'col-md-4 col-lg-3 col-xxl-4' : 'col-md-6 col-lg-4'">
							<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Nome/CNPJ/Chave</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus @keyup.enter="buscarNotas" placeholder="Busque por chave da nota, razão social, nome ou CNPJ do fornecedor" />
                  </div>
						<div class="mb-3" :class="dadosUsuario.usuarioPermissoes.includes('Fiscal | Importar notas') ? 'col-sm-7 col-md-4 col-lg-2' : 'col-9 col-md-4 col-lg-3'">
                     <label><i class="far fa-calendar color-theme font-11 me-1"></i> Data</label>
                     <datePicker format="DD/MM/YYYY" :range="true" :clearable="false" :editable="false" v-model="pesquisa.data"></datePicker>
                  </div>
                  <div class="mb-3 px-0 align-self-end" :class="dadosUsuario.usuarioPermissoes.includes('Fiscal | Importar notas') ? 'col-sm-5 col-md-4 col-lg-3 col-xxl-2' : 'col-3 col-md-2 col-lg-1'">
							<div class="row">
                        <div class="col">
									<button type="button" class="btn btn-primary w-100" @click="buscarNotas"><i class="fas fa-search font-13"></i></button>
                        </div>
                        <div class="col-8" v-if="dadosUsuario.usuarioPermissoes.includes('Fiscal | Importar notas')">
									<button type="button" class="btn btn-primary w-100" @click="abrirModal"><i class="far fa-upload font-13 me-1"></i> Importar</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>
						<div class="col-12 px-0">
							<div class="cards-150 cards-sm-225 cards-lg-250">
								<nota v-for="(nota, index) in pesquisa.retorno.resultado" :key="index" :nota="nota" :index="index" :showCancelar="true" @visualizar="buscarNota($event)" />
							</div>
						</div>
					</div>
				</div>
			</div>
      </div>

		<!-- modalImportarNota -->
      <div class="modal fade" id="modalImportarNota" tabindex="-1" aria-labelledby="modalImportarNotaLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalImportarNotaLabel">Importar nota</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-0">
							<div class="card-body p-12">
								<div class="row">
									<div class="col-12 mb-3">
										<label><i class="far fa-store color-theme font-11 me-1"></i> Loja *</label>
										<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="modal.loja" label="nomeFantasia" :reduce="loja => loja.id" placeholder="Selecione uma opção">
											<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
											<template v-slot:no-options>
												<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
											</template>
										</v-select>
										<select name="Mobile" class="form-control" v-model="modal.loja">
											<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
										</select>
									</div>
									<div class="col-12 mb-3">
										<label><i class="far fa-barcode color-theme font-11 me-1"></i> Chave *</label>
										<input type="text" class="form-control" v-model="modal.chave" maxlength="200" />
									</div>
									<div class="col-12">
										<div class="alert alert-secondary alert-dismissible fade show mb-0 shadow-none pe-3" role="alert">
											<strong>Importar</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
											<span>Insira os dados da nota e clique em "Importar" para importar uma NF-e da receita federal para o sistema.</span>
										</div>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="importarNota">Importar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
		</div>
   </div>
</template>

<script>

import $ from 'jquery'
import router from '@/router'
import { mapState } from 'vuex'
import nota from '@/components/fiscal/notas/Nota.vue'

export default {
	name: 'Notas',
	data: function () {
		return {
			pesquisa: {'status': null, 'loja': null, 'nome': '', 'modelo': '55', 'data': [new Date(), new Date()], 'retorno': {'status': null, 'loja': null, 'nome': '', 'resultado': []}},
			modal: {'chave': '', 'loja': null}
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      },
      'pesquisa.data' : function (value) {
         for (let i = 0; i < value.length; i++) {
            if (!(value[i] instanceof Date)) {
               value[i] = new Date(value[i])
            }
         }
      }
	},
	components: {
		nota
	},
	methods: {
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Notas',
				'resultado': this.pesquisa.retorno.resultado, 
				'campos':  ['id', 'nomeEmitente', 'razaoSocialEmitente', 'cpfCnpjEmitente', 'inscricaoEstadualEmitente', 'nomeDestinatario', 'razaoSocialDestinatario', 'cpfCnpjDestinatario', 'inscricaoEstadualDestinatario', 'chave', 'modelo', 'numeroNota', 'dataNota', 'status', 'dataRecebimento', 'dataFinalizado', 'valor', 'desconto', 'pis', 'cofins', 'ipi', 'valorIcmsTrib', 'valorIcmsSt', 'valorFrete'], 
				'lista': {'nome': 'boletos', 'campos': ['id', 'codigo', 'numeroDaParcela', 'dataDeVencimento', 'valor']}
			})
		},
		abrirModal : function () {
			Object.assign(this.$data.modal, this.$options.data.apply(this).modal)
			$('#modalImportarNota').modal('show')
		},
		buscarNotas : function () {
			if (Math.round((this.pesquisa.data[1].getTime() - this.pesquisa.data[0].getTime()) / (24 * 60 * 60 * 1000)) >= 90) {
				this.$toast.fire({
					icon: 'error',
					title: 'Período da busca maior que 90 dias.'
				});
	
				return
			}
			
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

			this.$axios({
				method: 'post',
				url: this.urlRest +'fiscal/searchNotas',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		importarNota : function () {
			if (this.modal.loja == null) {
				this.$toast.fire({
					icon: 'error',
					title: 'Informe uma loja!'
				});

				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'fiscal/importarNota',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

			}).then(() => {
				$('#modalImportarNota').modal('hide')

				this.$toast.fire({
					icon: 'success',
					title: 'Nota importada!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarNota : function (nota) {
			let tabNota = this.tabs.find(tab => tab.nome == 'Nota' && tab.query.chaveNota == nota.chave)
			router.push('/nota/'+ (tabNota ? tabNota.id : this.tabs.length) +'?chaveNota='+ nota.chave)
		}
	},
	mounted() {
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>